.projects {
  padding-block: 8rem;
}

.projects-title {
  margin-inline: auto;
  width: fit-content;
  margin-bottom: 5rem;
  font-size: 4rem;
  font-family: var(--font-secondary);
  opacity: 0;
}

hr {
  width: 70%;
  margin-inline: auto;
  margin-top: 20px;
  border-width: 2px;
}

@media (max-width: 800px) {
  .projects-title {
    font-size: 3rem;
  }

  .projects {
    padding-block: 4rem 0;
  }
}

@media (max-width: 300px) {
  .projects-title {
    font-size: 2rem;
  }
}
