.nav {
  padding: 25px 40px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}

svg {
  max-width: 200px;
  max-height: 40px;
}

.nav-list {
  gap: 20px;
  align-items: center;
  font-size: 1.2rem;
  text-decoration: none;
}

.nav li:nth-child(4),
.mobile-nav li:nth-child(4) {
  color: var(--blue-primary-400);
  font-weight: bold;
  cursor: pointer;
}

.mobile-nav {
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  position: relative;
  font-weight: bold;
}

.mobile-nav.open {
  animation: slideDown 1s forwards;
}

.mobile-nav.closed {
  animation: slideUp 1s forwards;
}

.mobile-nav ul {
  gap: 1rem;
  padding-block: 1.5rem;
  opacity: 0;
}

.mobile-nav.open ul {
  animation: fadeIn 2s forwards;
  animation-delay: 0.4s;
}

.mobile-nav.closed ul {
  animation: fadeOut 2s forwards;
}

@media (max-width: 600px) {
  svg {
    max-width: 150px;
    max-height: 30px;
  }
}

@media (max-width: 300px) {
  .nav {
    padding: 25px 20px;
  }

  svg {
    max-width: 100px;
    max-height: 20px;
  }
}
