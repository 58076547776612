.contact {
  padding-block: 4rem;
  background-color: var(--blue-secondary-500);
  position: relative;
  color: var(--clr-white);
}

.contact-info {
  gap: 4rem;
}

.contact-header {
  margin-inline: auto;
}

.contact-header h1 {
  margin-bottom: 1.5rem;
}

.info {
  opacity: 0;
}

.info p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  word-break: break-all;
}

@media (max-width: 1100px) {
  .info {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}

@media (max-width: 500px) {
  .contact-info {
    gap: 3rem;
  }

  .info p {
    font-size: 0.8rem;
  }
}

@media (max-width: 300px) {
  .contact-header h1 {
    font-size: 1.2rem;
  }

  .contact-header p {
    font-size: 0.8rem;
  }

  .info p {
    font-size: 0.6rem;
  }
}
