.landing {
  margin-block: 8rem;
}

.linkedIn {
  margin-top: 1rem;
  opacity: 0;
  animation: fadeIn 2s forwards;
  animation-delay: 2s;
}

.title-wrapper {
  line-height: 1.4;
}

.title {
  color: var(--blue-primary-500);
  font-size: 5rem;
  opacity: 0;
  animation: fadeIn 2s forwards;
}

.subtitle {
  font-size: 2rem;
  opacity: 0;
  animation: fadeIn 2s forwards;
  animation-delay: 1s;
}

.portrait {
  display: inline-block;
  width: 400px;
  height: 400px;
  background-image: linear-gradient(
    to bottom,
    var(--blue-primary-500) 0%,
    var(--clr-white) 100%
  );
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 2s forwards;
}

.portrait img {
  object-fit: cover;
  height: 120%;
  width: 100%;
}

@media (max-width: 1100px) {
  .landing {
    margin-block: 4rem;
  }
  .landing-content {
    flex-direction: column;
    justify-content: center;
    gap: 6rem;
  }

  .portrait {
    margin-inline: auto;
  }
}

@media (max-width: 800px) {
  .landing {
    margin-block: 2rem;
  }

  .title {
    font-size: 3rem;
  }

  .title-wrapper {
    gap: 0.4rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .portrait {
    height: 300px;
    width: 300px;
  }

  .linkedIn {
    margin-top: 0.2rem;
  }
}

@media (max-width: 300px) {
  .landing-content {
    gap: 3rem;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .linkedIn {
    font-size: 0.8rem;
  }

  .portrait {
    height: 200px;
    width: 200px;
  }
}
