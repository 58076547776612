.project {
  width: fit-content;
  border-radius: 2rem;
  margin-inline: auto;
  max-width: 100%;
  margin-bottom: 5rem;
  box-shadow: 0.5rem 1rem 3rem var(--blue-primary-100);
  position: relative;
}
.p-img {
  border-radius: 2rem;
  height: 25rem;
  width: 25rem;
  position: relative;
}

.p-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2rem;
}

.live {
  cursor: pointer;
}

.project-links {
  gap: 10px;
  margin: 20px auto 0;
}

.p-content {
  line-height: 1.7;
  padding: 30px;
  position: relative;
}

.p-content h1 {
  padding-bottom: 10px;
  color: var(--blue-primary-700);
}

.p-content p {
  max-width: 600px;
  margin-bottom: 40px;
}

@media (max-width: 1050px) {
  .project {
    flex-direction: column;
  }

  .p-img {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .p-content h1 {
    font-size: 1.5rem;
  }
  .p-content p {
    font-size: 0.8rem;
    max-width: 100%;
    text-align: center;
  }
}
