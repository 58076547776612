.about {
  background-color: var(--blue-primary-700);
  color: var(--clr-white);
  margin-top: 15rem;
  padding-block: 8rem;
}

.story {
  max-width: 35rem;
  line-height: 2.2;
  position: relative;
  justify-content: center;
  opacity: 0;
}

.circle {
  background-image: linear-gradient(
    to bottom,
    var(--blue-secondary-400) 0%,
    var(--clr-white) 100%
  );
  border-radius: 50%;
  height: 18rem;
  width: 18rem;
  position: absolute;
  opacity: 0.1;
  top: -3rem;
  left: -3rem;
}

.story h1 {
  font-size: 2rem;
  margin-bottom: 0.8rem;
}

@media (max-width: 1200px) {
  .about {
    margin-top: 10rem;
  }

  .about-content {
    flex-direction: column;
    justify-content: center;
    gap: 10rem;
    align-items: center;
  }
}

@media (max-width: 800px) {
  .about {
    margin-top: 8rem;
  }
}

@media (max-width: 300px) {
  .story h1 {
    font-size: 1.5rem;
  }

  .story p {
    font-size: 0.8rem;
  }
}
