.skills {
  background-color: var(--clr-white);
  height: 25rem;
  width: 25rem;
  border-radius: 10%;
  color: black;
  padding: 2.5rem;
  position: relative;
  z-index: 1;
  opacity: 0;
}

.skills h1 {
  margin-bottom: 1rem;
  color: var(--blue-primary-500);
}

.skills::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10%;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: var(--clr-white);
  transform: rotate(8deg);
  z-index: -1;
}

.skills-list {
  gap: 5rem;
}

.skills-list li {
  font-size: 1rem;
  font-weight: 600;
}

.skills-list ul {
  line-height: 2.5;
}

@media (max-width: 500px) {
  .skills {
    height: 20rem;
    width: 20rem;
    padding: 1.5rem;
  }

  .skills-list {
    gap: 2rem;
  }

  .skills-list ul {
    line-height: 2;
  }

  .skills-list li {
    font-size: 0.9rem;
  }

  .skills h1 {
    font-size: 1.6rem;
  }
}

@media (max-width: 300px) {
  .skills {
    height: 15rem;
    width: 15rem;
    padding: 1rem;
  }

  .skills-list ul {
    line-height: 2;
  }

  .skills-list li {
    font-size: 0.6rem;
  }

  .skills h1 {
    font-size: 1.6rem;
  }
}
